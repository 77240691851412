<template>
  <div class="block-loader" :style="rootStyle">
    <div class="block-loader__icon">
      <nuxt-icon name="logo" />
    </div>
    <div v-if="text" class="block-loader__text">
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  name: 'BlockLoader',
  props: {
    text: { type: String, default: null },
    minHeight: { type: Number, default: null },
    fullHeight: { type: Boolean, default: () => false },
    noRadius: { type: Boolean, default: () => false },
  },
  setup(props) {
    const rootStyle = computed(() => ({
      minHeight: props.fullHeight
        ? '100vh'
        : props.minHeight
        ? props.minHeight + 'px'
        : undefined,
      borderRadius: props.noRadius ? '0' : undefined,
    }))

    return {
      rootStyle,
    }
  },
}
</script>

<style scoped lang="scss">
.block-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  background: linear-gradient(
    135deg,
    var(--hb-primary),
    var(--hb-secondary),
    var(--hb-red2)
  );
  background-size: 400% 400%;
  animation: gradient-bg 15s ease infinite;

  &__icon {
    display: block;
    font-size: 48px;
    color: var(--hb-white);
    animation: pulse 4s ease infinite;
  }

  &__text {
    margin-top: 70px;
    color: var(--hb-white);
    font-weight: 600;
    font-size: 26px;
  }
}

@keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse {
  50% {
    transform: scale(1.8);
  }
}
</style>
